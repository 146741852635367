
.members-list .letter {
    color: var(--secondary-color);
}
.members-list .letter.heading-block {
    margin-bottom: 10px;
}
.members-list .letter.heading-block::after {
    margin-top:0px;
}
.members-list .member + .letter {
    margin-top:20px;
}

/* We set the size of the picture */
.members-list .member-image {
    height:100px;
    width: 100px;
    padding-top:5px;
    padding-bottom:5px;
    margin-right: 30px;
    flex: 0 0 auto;
}

/* We set the size of the picture */
.members-list .member-image > a {
    height:100%;
}

/* We set the size of the picture */
.members-list .member-image img {
    width: auto;
    height: 100%;
    margin-left:auto;
    margin-right:auto;
}

/* We set shadow and margin for product list item */
.members-list .member {
    margin: 0 0 10px 0;
    padding: 0;
    width: 100%;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
}

/* We ensure that picture is verticaly aligned with content */
.members-list .grid-inner {
    display:flex;
    align-items: center;
}

/* We ensure that picture and content wrap for mobile */
/* picture takes full width and with small margin bottom */
@media (max-width: 767.98px) {
.members-list .member-image {
        width: 100%;
        margin-bottom: 20px;
}
.members-list .grid-inner {
      flex-wrap: wrap;
}
}

/* We display the content next to the picture */
.members-list .member-desc {
    padding-top:5px;
    padding-right:10px;
    padding-bottom: 0px;

    flex: 0 1 auto;
    align-self: stretch;

    display: flex;
    flex-direction: column;
}
@media (max-width: 767.98px) {
.members-list .member-desc {
      padding-left:10px;
}
}
.members-list .member-desc h3 {
    margin-bottom: 10px;
}




